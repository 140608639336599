import { useCallback } from "react";
import { useStoreContext } from "../../store/store-context";
import { AuthAPIController } from "../../components/auth/auth.controller";
import { authApiRequestImpl } from "../../components/auth/auth.request";

export const useUserHook = () => {
	const { setAuth } = useStoreContext();
	const localStorageAuth = localStorage.getItem("auth");
	const getProfile = localStorageAuth ? JSON.parse(localStorageAuth) : null;

	const getUserProfile = useCallback(async () => {
		const request = new authApiRequestImpl();
		const controller = new AuthAPIController(request);
		const res = await controller.getUserProfile();
		if (res) {
			getProfile["user"] = res.data;
			localStorage.setItem("auth", JSON.stringify(getProfile));
			setAuth(getProfile);
		}
	}, [getProfile, setAuth]);

	return getUserProfile;
};
