import { Box, Flex, Image, Text, Button, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { Controller } from "react-hook-form";
import { Link } from "@chakra-ui/react";
import { TextField } from "../../components/ui/textfield";
import { NavBar } from "./signup";
import { useAuthHook } from "../../components/auth/auth-hook";

export default function Login() {
	const {
		handleInput,
		errors,
		handleSubmit,
		onSubmit,
		isDisabled,
		isSubmitting,
		control,
	} = useAuthHook();

	const navigate = useNavigate();
	return (
		<Box width="100%">
			<NavBar />
			<Flex>
				<Box
					width="60%"
					bg={`linear-gradient(to bottom, #0A7C15BD, #0A7C15BD),url('/slide.jpg')`}
					height="100vh"
					display={["none", "block"]}
				>
					<Flex
						flexDirection={"column"}
						height="100%"
						padding="40px"
						justifyContent="center"
						alignItems={"flex-start"}
					>
						<Image src="/cip-white-trans.png" width="200px" height="130px" />
						<Text
							fontSize="44px"
							lineHeight={"66px"}
							fontWeight="500"
							color="white"
						>
							At your service, always!
						</Text>
					</Flex>
				</Box>
				<Box width={["100%", "30%"]} margin="10% 80px">
					<form method="post" onSubmit={handleSubmit(onSubmit)}>
						<Text fontSize="16px" color="rgba(0, 0, 0, 0.5)">
							Enter phone number and password
						</Text>
						<Spacer height="20px" />
						<Box>
							<Controller
								control={control}
								name="phone"
								render={() => (
									<TextField
										type="number"
										name="phone"
										label="Phone number"
										// ref={register}
										error={!!errors.phone ? errors.phone.message : ""}
										placeholder="Phone number*"
										onChange={(e) => handleInput("phone", e.target.value)}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />

						<Box>
							<Controller
								control={control}
								name="password"
								render={() => (
									<TextField
										type="password"
										name="password"
										label="Password"
										// ref={register}
										error={!!errors.password ? errors.password.message : ""}
										placeholder="Password"
										onChange={(e) => handleInput("password", e.target.value)}
									/>
								)}
							/>
						</Box>
						<Spacer height="50px" />

						<Box
							width="100%"
							display="flex"
							flexDir="column"
							alignItems="center"
							marginTop="10px"
						>
							<Button
								color="white"
								fontSize="20px"
								width="238px"
								height="62px"
								type="submit"
								disabled={isDisabled}
								data-form-action={true}
								isLoading={isSubmitting}
								aria-busy={isSubmitting}
								bg="#0A7C15"
								borderRadius="10px"
							>
								Login
							</Button>
							<Text marginTop="20px">
								Forgot Password?{" "}
								<Link
									color="blue"
									onClick={() => navigate("/forgotten-password")}
									textDecoration={"underline"}
									fontWeight="500"
								>
									Click here
								</Link>
							</Text>
							<Spacer height="50px" />
							<Text marginTop="20px">
								Don’t have an account?{" "}
								<Link
									color="#0A7C15"
									onClick={() => navigate("/signup")}
									textDecoration={"underline"}
									fontWeight="500"
								>
									Sign up
								</Link>
							</Text>
						</Box>
					</form>
				</Box>
			</Flex>
		</Box>
	);
}
