import { AxiosResponse } from "axios";
import { AppStore } from "../../store/store";
import { BaseRequest } from "../../configs/requests";

export interface IAuthRequest extends BaseRequest {
	login(data: any): Promise<any>;
	signup(data: any): Promise<any>;
	validateToken(): Promise<any>;
	userProfile(): Promise<any>;
	forgotPassword(data: any): Promise<any>;
	resetPassword(data: any, token: string): Promise<any>;
	refreshToken(): Promise<any>;
	getNotification(): Promise<any>;
	getDashboardNotification(): Promise<any>;
}

export class authApiRequestImpl extends BaseRequest implements IAuthRequest {
	loginEndpoint: string = "/auth/login";
	registerEndpoint: string = "/auth/register";

	async login(data: any): Promise<any> {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`${this.loginEndpoint}`,
			data
		);
		return response;
	}

	async signup(data: any): Promise<any> {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`${this.registerEndpoint}`,
			data
		);
		return response;
	}
	async forgotPassword(data: any): Promise<any> {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`/auth/forgot_password`,
			data
		);
		return response;
	}
	async resetPassword(data: any, token: string): Promise<any> {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`/auth/reset_password`,
			data,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	}

	async validateToken() {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`/auth/validate_token`,
			{},
			{
				headers: {
					Authorization: `Bearer ${AppStore.token}`,
				},
			}
		);
		return response;
	}
	async refreshToken() {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`/auth/refresh`,
			{},
			{
				headers: {
					Authorization: `Bearer ${AppStore.token}`,
				},
			}
		);
		return response;
	}
	async userProfile() {
		const { data: response } = await this.request.post<any, AxiosResponse<any>>(
			`/auth/user_profile`,
			{},
			{
				headers: {
					Authorization: `Bearer ${AppStore.token}`,
				},
				useCache: false,
			}
		);
		return response;
	}
	async getNotification() {
		const { data: response } = await this.request.get<any, AxiosResponse<any>>(
			`/home_notification`
		);
		return response;
	}
	async getDashboardNotification() {
		const { data: response } = await this.request.get<any, AxiosResponse<any>>(
			`/notification`
		);
		return response;
	}
}
