import { useState, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { authApiRequestImpl } from "./auth.request";
import { AuthAPIController } from "./auth.controller";
import { useToast } from "@chakra-ui/react";
import { useStoreContext } from "../../store/store-context";
import { useLocation } from "react-router";
import { parseErrorContent } from "../../utils/error-handling";
import { useNavigate } from "react-router";
import {
	SignInValidator,
	SignupValidator,
	ResetPasswordValidator,
	ForgotPasswordValidator,
} from "./auth-validator";
import { useForm, Resolver } from "react-hook-form";

export type SignInFormData = {
	phone: number;
	password: string;
};
export type ForgetPasswordData = {
	email: string;
};
export type ResetPasswordData = {
	email: string;
	password: string;
	confirmPassword: string;
};
export type SignupFormData = {
	phone: number;
	name: string;
	email: string;
	bvn: number;
	nin: number;
	password: string;
};
export const useForgetPasswordHook = () => {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch,
	} = useForm<ForgetPasswordData>({
		resolver: yupResolver(ForgotPasswordValidator) as Resolver<
			ForgetPasswordData,
			object
		>,
		mode: "onSubmit",
		defaultValues: {
			email: "",
		},
	});
	const hasError = useMemo(() => {
		return formState.submitCount > 0 && formState.errors;
	}, [formState.errors, formState.submitCount]);

	const handleInput = (type: "email", value: string) => {
		setValue(type, value);
	};
	const isSubmitting = useMemo(() => {
		return formState.isSubmitting;
	}, [formState]);

	const toast = useToast();

	const [isDisabled, setIsDisabled] = useState(true);

	const { email } = watch();

	useMemo(() => {
		email ? setIsDisabled(false) : setIsDisabled(true);
	}, [email]);

	const onSubmit = async (data: ForgetPasswordData) => {
		try {
			const request = new authApiRequestImpl();
			const controller = new AuthAPIController(request);
			const response: any = await controller.forgetPassword({ email });

			response &&
				toast({
					title: response?.message,
					description: "",
					status: "success",
					position: "top",
					duration: 4000,
					isClosable: true,
				});
		} catch (error: any) {
			toast({
				title: parseErrorContent(error),
				description: "",
				size: "3xl",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return {
		hasError,

		isSubmitting,
		control,

		onSubmit,

		isDisabled,
		handleSubmit,

		handleInput,
		register,
		errors,
	};
};
export const useResetPasswordHook = () => {
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch,
	} = useForm<ResetPasswordData>({
		resolver: yupResolver(ResetPasswordValidator) as Resolver<
			ResetPasswordData,
			object
		>,
		mode: "onSubmit",
		defaultValues: {
			password: "",
			confirmPassword: "",
		},
	});
	const location = useLocation();

	const hasError = useMemo(() => {
		return formState.submitCount > 0 && formState.errors;
	}, [formState.errors, formState.submitCount]);

	const handleInput = (type: "password" | "confirmPassword", value: string) => {
		setValue(type, value);
	};
	const isSubmitting = useMemo(() => {
		return formState.isSubmitting;
	}, [formState]);
	const toast = useToast();
	const navigate = useNavigate();

	const [isDisabled, setIsDisabled] = useState(true);

	const { password, confirmPassword } = watch();

	useMemo(() => {
		password && confirmPassword ? setIsDisabled(false) : setIsDisabled(true);
	}, [password, confirmPassword]);

	const params = Object.fromEntries(new URLSearchParams(location.search));

	const onSubmit = async (data: ResetPasswordData) => {
		try {
			const request = new authApiRequestImpl();
			const controller = new AuthAPIController(request);
			const response: any = await controller.resetPassword(
				{ email: params?.email, password: password },
				params?.key
			);

			response &&
				toast({
					title: response?.message,
					description: "",
					status: "success",
					position: "top",
					duration: 4000,
					isClosable: true,
				});
			response && navigate("/login");
		} catch (error: any) {
			toast({
				title: parseErrorContent(error),
				description: "",
				size: "3xl",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return {
		hasError,

		isSubmitting,
		control,

		onSubmit,

		isDisabled,
		handleSubmit,

		handleInput,
		register,
		errors,
	};
};
export const useAuthHook = () => {
	const navigate = useNavigate();
	const { setAuth, setToken } = useStoreContext();
	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		formState,
		setValue,
		watch,
	} = useForm<SignInFormData>({
		resolver: yupResolver(SignInValidator) as Resolver<SignInFormData, object>,
		mode: "onSubmit",
		defaultValues: {
			phone: 0,
			password: "",
		},
	});

	const {
		register: SignupRegister,
		handleSubmit: SignupHandleSubmit,
		formState: { errors: SignupErrors },
		formState: SignUpFormState,
		control: signUpControl,
		setValue: SignUpSetValue,
		watch: SignupWatch,
	} = useForm<SignupFormData>({
		resolver: yupResolver(SignupValidator) as Resolver<SignupFormData, object>,
		mode: "onSubmit",
		defaultValues: {
			phone: 0,
			name: "",
			email: "",
			password: "",
		},
	});

	const handleInput = (type: "password" | "phone", value: string) => {
		setValue(type, value);
	};

	const handleRegisterInput = (
		type: "password" | "phone" | "name" | "email" | "bvn" | "nin",
		value: string
	) => {
		SignUpSetValue(type, value);
	};

	const [isDisabled, setIsDisabled] = useState(true);

	const { phone, password } = watch();
	const {
		phone: registerPhone,
		password: registerPassword,
		email: registerEmail,
		name: registerName,
		bvn: registerBvn,
		nin: registerNin,
	} = SignupWatch();

	useMemo(() => {
		phone && password ? setIsDisabled(false) : setIsDisabled(true);
	}, [phone, password]);

	const isSubmitting = useMemo(() => {
		return formState.isSubmitting;
	}, [formState]);

	const registerSubmitting = useMemo(() => {
		return SignUpFormState.isSubmitting;
	}, [SignUpFormState]);

	const [registerDisabled, setRegisterDisabled] = useState(true);

	useMemo(() => {
		registerPhone && registerPassword && registerEmail && registerName && registerBvn && registerNin
			? setRegisterDisabled(false)
			: setRegisterDisabled(true);
	}, [registerPhone, registerPassword, registerEmail, registerName, registerBvn, registerNin]);

	const hasError = useMemo(() => {
		return formState.submitCount > 0 && formState.errors;
	}, [formState.errors, formState.submitCount]);

	// Toast for handle error messages

	const toast = useToast();

	// Login submit handler

	const onSubmit = async (data: SignInFormData) => {
		try {
			const request = new authApiRequestImpl();
			const controller = new AuthAPIController(request);
			const response: any = await controller.login(data);

			response &&
				toast({
					title: "Login Successful",
					description: "",
					status: "success",
					duration: 4000,
					isClosable: true,
				});
			const auth = {
				token: response.access_token,
				user: response?.user.data,
				expires: response.user.exp,
			};
			response && localStorage.setItem("auth", JSON.stringify(auth));
			response &&
				localStorage.setItem("token", JSON.stringify(response.access_token));
			response && setAuth(auth);
			response && setToken(response.access_token);
			setTimeout(() => {
				response && navigate("/dashboard");
			}, 200);
		} catch (error: any) {
			toast({
				title: parseErrorContent(error),
				description: "",
				size: "3xl",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	const handleRegisterSubmit = async (data: SignupFormData) => {
		try {
			const request = new authApiRequestImpl();
			const controller = new AuthAPIController(request);
			const response: any = await controller.register(data);

			if (response && response.status) {
				toast({
				  title: response.message,
				  description: "",
				  status: "success",
				  duration: 4000,
				  isClosable: true,
				});
				navigate("/login");
			  } else {
				toast({
				  title: response.message || "Registration failed",
				  description: "",
				  size: "3xl",
				  status: "error",
				  duration: 4000,
				  isClosable: true,
				});
			  }
			// response &&
			// 	toast({
			// 		title: response.message,
			// 		description: "",
			// 		status: "success",
			// 		duration: 4000,
			// 		isClosable: true,
			// 	});
			// response && navigate("/login");
		} catch (error: any) {
			toast({
				title: parseErrorContent(error),
				description: "",
				size: "3xl",
				status: "error",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return {
		hasError,
		SignupRegister,
		SignupHandleSubmit,
		SignupErrors,
		SignUpFormState,
		isSubmitting,
		control,
		signUpControl,
		handleRegisterSubmit,
		onSubmit,
		SignupWatch,
		handleRegisterInput,
		isDisabled,
		handleSubmit,
		registerDisabled,
		registerSubmitting,
		handleInput,
		register,
		errors,
	};
};
