import { RefObject, useLayoutEffect, useRef } from "react";

export function useCombinedRefs<T>(...refs: RefObject<T>[]) {
	const targetRef = useRef<T>(null);

	useLayoutEffect(() => {
		for (const ref of refs) {
			if (!ref) continue;
			if (typeof ref === "function") {
				// @ts-ignore
				ref(targetRef.current);
			} else {
				// @ts-ignore
				ref.current = targetRef.current;
			}
		}
	}, [refs]);

	return targetRef;
}
