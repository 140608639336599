import { createContext, useContext, ReactNode } from "react";
import { AppStore } from "./store";

export const StoreProvider = createContext(AppStore);

export const useStoreContext = () => useContext(StoreProvider);

export const StoreContextProvider = ({ children }: { children: ReactNode }) => {
	return (
		<StoreProvider.Provider value={AppStore}>{children}</StoreProvider.Provider>
	);
};
