import { render } from "react-dom";
import * as Sentry from "@sentry/react";

import { installClient } from "./install";

export const renderClient = () => {
	const { Page } = installClient();
	Sentry.init({
		dsn: "https://4467fd94ffdc4997bff5de1cbd7be6fc@o4505001479766016.ingest.sentry.io/4505001481928704",
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
	render(Page, document.getElementById("root"));
};
