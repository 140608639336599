import { Suspense, PropsWithChildren } from "react";
import { StoreContextProvider } from "./store/store-context";
import { PageLoader } from "./components/ui/page-loader";

import { ChakraProvider } from "@chakra-ui/react";

function App({ children }: PropsWithChildren<{}>) {
	return (
		<StoreContextProvider>
			<ChakraProvider>
				<Suspense fallback={<PageLoader />}>{children}</Suspense>
			</ChakraProvider>
		</StoreContextProvider>
	);
}

export default App;
