import { ReactNode } from "react";
import {
	Box,
	Flex,
	Heading,
	Text,
	Stack,
	Container,
	Avatar,
	useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = ({ children }: { children: ReactNode }) => {
	return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
	return (
		<Stack
			bg={useColorModeValue("white", "gray.800")}
			boxShadow={"lg"}
			p={8}
			rounded={"xl"}
			align={"center"}
			pos={"relative"}
			_after={{
				content: `""`,
				w: 0,
				h: 0,
				borderLeft: "solid transparent",
				borderLeftWidth: 16,
				borderRight: "solid transparent",
				borderRightWidth: 16,
				borderTop: "solid",
				borderTopWidth: 16,
				borderTopColor: useColorModeValue("white", "gray.800"),
				pos: "absolute",
				bottom: "-16px",
				left: "50%",
				transform: "translateX(-50%)",
			}}
		>
			{children}
		</Stack>
	);
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
	return (
		<Heading as={"h3"} fontSize={"xl"}>
			{children}
		</Heading>
	);
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
	return (
		<Text
			textAlign={"center"}
			color={useColorModeValue("gray.600", "gray.400")}
			fontSize={"sm"}
		>
			{children}
		</Text>
	);
};

const TestimonialAvatar = ({
	src,
	name,
	title,
}: {
	src: string;
	name: string;
	title: string;
}) => {
	return (
		<Flex align={"center"} mt={8} direction={"column"}>
			<Avatar src={src} mb={2} />
			<Stack spacing={-1} align={"center"}>
				<Text fontWeight={600}>{name}</Text>
				<Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
					{title}
				</Text>
			</Stack>
		</Flex>
	);
};

export default function WithSpeechBubbles() {
	return (
		<Box bg={useColorModeValue("gray.100", "gray.700")}>
			<Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
				<Stack spacing={0} align={"center"}>
					<Heading>Customers Feedback/Reviews.</Heading>
					<Text>We have been working amazing customers</Text>
				</Stack>
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: 10, md: 4, lg: 10 }}
				>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Great Services</TestimonialHeading>
							<TestimonialText>
								I enjoy all services you offer, how I wish 1Gb can go back to
								the price it was earlier #260.
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar
							src={""}
							name={"Segun Ayo"}
							title={"Segun from warri"}
						/>
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Best services</TestimonialHeading>
							<TestimonialText>
								You guys are the best I must say, have patronize alot of data
								resellers but none like u guys.
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar
							src={""}
							name={"Abel Agbo"}
							title={"Abel Agbo from Lagos"}
						/>
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>Mindblowing Service</TestimonialHeading>
							<TestimonialText>
								would like to say your service has been excellent, especially
								with respect to MTN gifting service as a reseller as well as
								your user interface and payment system. your services are quite
								commendable.
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar
							src={""}
							name={"Damilola Joy"}
							title={"I am Damilola from Uyo"}
						/>
					</Testimonial>
				</Stack>
			</Container>
		</Box>
	);
}
