import { lazy } from "react";
import { lazyRetry } from "../../utils/retryLazyLoad";
import { observer } from "mobx-react-lite";

const DashboardPage = lazy(() =>
	lazyRetry(() =>
		import("./dashboard").then((module) => ({
			default: module.default,
		}))
	)
);

export const installDashboard = () => {
	const Page = observer(() => <DashboardPage />);

	return {
		Page,
	};
};
