import { makeAutoObservable, observable, action } from "mobx";

export class Store {
	@action
	fetchAuthFromLocalStorage = () => {
		const localStorageAuth = localStorage.getItem("auth");
		return localStorageAuth ? JSON.parse(localStorageAuth) : null;
	};
	@observable profileLoaded = false;
	@observable dashboardNotification = "";

	@observable token = localStorage.getItem("token")
		? localStorage.getItem("token")
		: "";

	@observable dashboardRoutes = {
		home: true,
		user_profile: false,
		data: false,
		cable: false,
		payments: false,
		electricity: false,
		transactions: false,
	};
	@observable
	auth: null | any = this.fetchAuthFromLocalStorage();

	constructor() {
		makeAutoObservable(this);
	}

	@action setAuth = (data: any) => {
		this.auth = data;
	};
	@action setToken = (data: string) => {
		this.token = data;
	};
	@action setDashboardNotification = (data: string) => {
		this.dashboardNotification = data;
	};

	@action handleDashboardRoutes = (
		route:
			| "home"
			| "user_profile"
			| "transactions"
			| "data"
			| "payments"
			| "cable"
			| "electricity"
	) => {
		switch (route) {
			case "home":
				this.dashboardRoutes = {
					home: true,
					user_profile: false,
					transactions: false,
					data: false,
					cable: false,
					payments: false,
					electricity: false,
				};
				break;
			case "user_profile":
				this.dashboardRoutes = {
					home: false,
					user_profile: true,
					transactions: false,
					data: false,
					payments: false,

					cable: false,
					electricity: false,
				};
				break;
			case "transactions":
				this.dashboardRoutes = {
					home: false,
					user_profile: false,
					transactions: true,
					data: false,
					payments: false,

					cable: false,
					electricity: false,
				};
				break;
			case "data":
				this.dashboardRoutes = {
					home: false,
					user_profile: false,
					transactions: false,
					data: true,
					payments: false,

					cable: false,
					electricity: false,
				};
				break;
			case "cable":
				this.dashboardRoutes = {
					home: false,
					user_profile: false,
					transactions: false,
					data: false,
					cable: true,
					payments: false,

					electricity: false,
				};
				break;
			case "electricity":
				this.dashboardRoutes = {
					home: false,
					user_profile: false,
					transactions: false,
					data: false,
					payments: false,

					cable: false,
					electricity: true,
				};
				break;
			case "payments":
				this.dashboardRoutes = {
					home: false,
					user_profile: false,
					transactions: false,
					data: false,
					payments: true,

					cable: false,
					electricity: false,
				};
				break;
			default:
				break;
		}
	};
	@action activateProfileLoaded = () => {
		this.profileLoaded = true;
	};
	@action
	getAuthFromLocalStorage = () => {
		const localStorageAuth = localStorage.getItem("auth");
		this.auth = localStorageAuth ? JSON.parse(localStorageAuth) : undefined;
	};
}
export const AppStore = new Store();
