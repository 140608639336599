import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { MdCancel } from "react-icons/md";

export const NotificationBar = ({ notification }: { notification: string }) => {
	const [showNotification, setShowNotification] = useState(true);
	if (showNotification) {
		return (
			<Box
				width="100vw"
				padding="24px 10px "
				bg="green.500"
				display="flex"
				alignItems={"center"}
				justifyContent="space-between"
			>
				<Box></Box>
				<Text color="white" fontSize={"20px"} fontWeight="600" align="center">
					{notification}
				</Text>
				<Box onClick={() => setShowNotification(false)} cursor="pointer">
					<MdCancel color="white" />
				</Box>
			</Box>
		);
	}
	return <></>;
};
