import { Box, Flex, Image, Text, Button, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { Controller } from "react-hook-form";

import { useAuthHook } from "../../components/auth/auth-hook";
import { TextField } from "../../components/ui/textfield";
import { Link } from "@chakra-ui/react";

export const NavBar = () => {
	return (
		<>
			<Box boxShadow={"xl"} padding="5%" display={["block", "none"]}>
				<Link href="/">
					<Image src="/cip-green.png" />
				</Link>
			</Box>
		</>
	);
};

export default function Signup() {
	const navigate = useNavigate();
	const {
		SignupErrors,
		signUpControl,
		handleRegisterInput,
		registerDisabled,
		SignupHandleSubmit,
		handleRegisterSubmit,
		registerSubmitting: isSubmitting,
	} = useAuthHook();
	return (
		<Box width="100%">
			<NavBar />
			<Flex>
				<Box
					width="60%"
					bg={`linear-gradient(to bottom, #0A7C15BD, #0A7C15BD),url('/slide.jpg')`}
					backgroundSize="cover"
					backgroundPosition="center"
					backgroundRepeat={"no-repeat"}
					height="100vh"
					display={["none", "block"]}
				>
					<Flex
						flexDirection={"column"}
						height="100%"
						padding="40px"
						justifyContent="center"
						alignItems={"flex-start"}
					>
						<Image src="/cip-white-trans.png" width="200px" height="130px" />
						<Text
							fontSize="44px"
							lineHeight={"66px"}
							fontWeight="500"
							color="white"
						>
							At your service, always!
						</Text>
					</Flex>
				</Box>
				<Box width={["100%", "30%"]} margin="4% 80px">
					<form
						action=""
						method="post"
						onSubmit={SignupHandleSubmit(handleRegisterSubmit)}
					>
						<Text fontSize="16px" color="rgba(0, 0, 0, 0.5)">
							To sign in type in your phone number <br /> and password
						</Text>
						<Spacer height="20px" />
						<Box>
							<Controller
								control={signUpControl}
								name="name"
								render={() => (
									<TextField
										type="text"
										name="full name"
										label="Full Name"
										error={!!SignupErrors.name ? SignupErrors.name.message : ""}
										placeholder="Full Name*"
										onChange={(e) =>
											handleRegisterInput("name", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />
						<Box>
							<Controller
								control={signUpControl}
								name="email"
								render={() => (
									<TextField
										type="text"
										name="email"
										label="Email Address"
										error={
											!!SignupErrors.email ? SignupErrors.email.message : ""
										}
										placeholder="Email*"
										onChange={(e) =>
											handleRegisterInput("email", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />

						<Box>
							<Controller
								control={signUpControl}
								name="phone"
								render={() => (
									<TextField
										type="number"
										name="phone"
										label="Phone number"
										error={
											!!SignupErrors.phone ? SignupErrors.phone.message : ""
										}
										placeholder="Phone number*"
										onChange={(e) =>
											handleRegisterInput("phone", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />

						<Box>
							<Controller
								control={signUpControl}
								name="bvn"
								render={() => (
									<TextField
										type="number"
										name="bvn"
										label="BVN"
										error={
											!!SignupErrors.bvn ? SignupErrors.bvn.message : ""
										}
										placeholder="BVN"
										onChange={(e) =>
											handleRegisterInput("bvn", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />

						<Box>
							<Controller
								control={signUpControl}
								name="nin"
								render={() => (
									<TextField
										type="number"
										name="nin"
										label="NIN"
										error={
											!!SignupErrors.nin ? SignupErrors.nin.message : ""
										}
										placeholder="NIN"
										onChange={(e) =>
											handleRegisterInput("nin", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="20px" />

						<Box>
							<Controller
								control={signUpControl}
								name="password"
								render={() => (
									<TextField
										type="password"
										name="password"
										label="Password"
										error={
											!!SignupErrors.password
												? SignupErrors.password.message
												: ""
										}
										placeholder="Password*"
										onChange={(e) =>
											handleRegisterInput("password", e.target.value)
										}
									/>
								)}
							/>
						</Box>
						<Spacer height="50px" />

						<Box
							width="100%"
							display="flex"
							flexDir="column"
							alignItems="center"
							marginTop="10px"
						>
							<Button
								color="white"
								fontSize="20px"
								width="238px"
								height="62px"
								disabled={registerDisabled}
								type="submit"
								data-form-action={true}
								isLoading={isSubmitting}
								aria-busy={isSubmitting}
								bg="#0A7C15"
								borderRadius="10px"
							>
								Sign up
							</Button>
							<Spacer height="50px" />
							<Text marginTop="20px">
								Already have an account?{" "}
								<Link
									color="#0A7C15"
									onClick={() => navigate("/login")}
									textDecoration={"underline"}
									fontWeight="500"
								>
									Login
								</Link>
							</Text>
						</Box>
					</form>
				</Box>
			</Flex>
		</Box>
	);
}
