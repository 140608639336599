import { Route, Navigate } from "react-router";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import NotFoundPage from "../pages/404";
import { lazy } from "react";
import { useLocation, useNavigate } from "react-router";
import Home from "../pages/home/home";
import { useUserHook } from "../pages/dashboard/user-hook";
import { useCallback, useMemo } from "react";
import { AuthAPIController } from "../components/auth/auth.controller";
import { authApiRequestImpl } from "../components/auth/auth.request";
import { installDashboard } from "../pages/dashboard/dashboard-install";
import { useStoreContext } from "../store/store-context";
import { observer } from "mobx-react-lite";
import { lazyRetry } from "../utils/retryLazyLoad";
import { ResetPassword } from "../pages/auth/reset-password";
import { useQuery } from "react-query";
import ForgottenPassword from "../pages/auth/forgotten-password";
import { Routes } from "react-router-dom";

export function BaseRoot() {
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/signup" element={<Signup />} />
			<Route path="/forgotten-password" element={<ForgottenPassword />} />
			<Route path="/reset_password" element={<ResetPassword />} />
			<Route path="/not-found" element={<NotFoundPage />} />
			<Route path="/" element={<Home />} />
			<Route path="/*" element={<Navigate to={"/"} />} />
		</Routes>
	);
}

export const DashboardRoutes = () => {
	const { Page: DashboardPage } = installDashboard();

	const refreshToken = useCallback(async () => {
		const request = new authApiRequestImpl();
		const controller = new AuthAPIController(request);
		return await controller.refreshToken();
	}, []);
	const getUserProfile = useUserHook();

	useQuery("refreshToken", () => refreshToken(), {
		refetchInterval: 240000,
	});

	useQuery("getProfile", () => getUserProfile());

	const fetchNotificationMessage = useCallback(async () => {
		const request = new authApiRequestImpl();
		const controller = new AuthAPIController(request);
		const response = await controller.getDashboardNotification();
		return response;
	}, []);
	const { setDashboardNotification } = useStoreContext();

	const { data } = useQuery("notification", () => fetchNotificationMessage());

	useMemo(() => {
		if (data) {
			setDashboardNotification(data?.message);
		}
	}, [setDashboardNotification, data]);

	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/login" element={<Login />} />
				<Route path="/dashboard" element={<DashboardPage />} />
				<Route path="/*" element={<Navigate to={"/"} />} />
			</Routes>
		</>
	);
};
const RoutesProvider = lazy(() =>
	lazyRetry(() =>
		import("./routes").then((module) => ({ default: module.RoutesProvider }))
	)
);

export const installRoutes = () => {
	const RootRoutesComponent = observer(() => {
		const { auth, getAuthFromLocalStorage } = useStoreContext();
		const location = useLocation();
		const navigate = useNavigate();
		const validateToken = useCallback(async () => {
			try {
				const request = new authApiRequestImpl();
				const controller = new AuthAPIController(request);
				const res = await controller.validateToken();

				if (res && auth === null) {
					getAuthFromLocalStorage();
				}
			} catch {
				if (location.pathname.includes("/dashboard")) navigate("/");
				localStorage.clear();
				sessionStorage.clear();
				getAuthFromLocalStorage();
			}
		}, [auth, getAuthFromLocalStorage, location.pathname, navigate]);

		useQuery("validateToken", () => validateToken(), {
			enabled: localStorage.getItem("auth") !== null,
		});

		const RenderRoute = !!auth ? DashboardRoutes : BaseRoot;

		return <RenderRoute />;
	});

	const Routes = observer(() => (
		<RoutesProvider Routes={RootRoutesComponent} />
	));
	return {
		Routes,
	};
};
