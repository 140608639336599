import { FormLabel, Input, Stack, Button, Text } from "@chakra-ui/react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { useCombinedRefs } from "../../configs/combine-refs";
import {
	useState,
	ComponentPropsWithoutRef,
	Ref,
	RefObject,
	forwardRef,
	useRef,
} from "react";

interface ITextField extends ComponentPropsWithoutRef<"input"> {
	onChange?: (e: any) => void;
	placeholder: string;
	label?: string;
	height?: number | string;
	error?: string;
	name?: string;
	value?: string | number;
	type: string;
	disabled?: boolean;
	invalid?: boolean;
}

export const TextField = forwardRef(
	(
		{
			onChange,
			placeholder,
			label,
			error,
			invalid,
			height,
			disabled,
			value,
			type,
			name,
			...rest
		}: ITextField,
		ref: Ref<HTMLInputElement>
	) => {
		const [show, setShow] = useState(false);
		const handleClick = () => setShow(!show);
		const inputRef = useRef<HTMLInputElement>(null);
		const combinedRef = useCombinedRefs<HTMLInputElement>(
			ref as RefObject<HTMLInputElement>,
			inputRef
		);
		return (
			<Stack>
				{label && (
					<FormLabel fontSize={"16px"} fontWeight={600}>
						{label}
					</FormLabel>
				)}

				<InputGroup size="lg">
					<Input
						height={height ?? "14"}
						isInvalid={invalid}
						errorBorderColor="red.300"
						ref={combinedRef}
						placeholder={placeholder}
						isDisabled={disabled}
						name={name}
						value={value}
						autoComplete="on"
						borderRadius="10px"
						background="rgba(217, 217, 217, 0.25)"
						borderColor={error ? "red.400" : "#cecede"}
						borderWidth={error ? "2px" : ""}
						fontSize="17px"
						color={"rgba(0, 0, 0, 0.55)"}
						focusBorderColor={error ? "red.400" : "#0A7C15"}
						boxShadow="2xl"
						type={type === "password" ? (show ? "text" : "password") : type}
						onChange={onChange}
					/>
					{type === "password" && (
						<InputRightElement width="4.5rem" mt="1">
							<Button h="2rem" size="sm" onClick={handleClick}>
								{show ? "Hide" : "Show"}
							</Button>
						</InputRightElement>
					)}
				</InputGroup>
				{error && (
					<Text color={"red.400"} fontSize="12px" fontWeight={"500"}>
						{error}
					</Text>
				)}
			</Stack>
		);
	}
);
