import React, { StrictMode } from "react";
import { observer } from "mobx-react-lite";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";
import { installRoutes } from "./routes/base-roots";

export function installClient() {
	const { Routes } = installRoutes();
	const queryClient = new QueryClient();
	const AppView = observer(() => <App children={<Routes />} />);

	const Page = (
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<AppView />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</StrictMode>
	);

	return {
		Page,
	};
}
