import {
	Box,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
	Text,
} from "@chakra-ui/react";

interface StatsCardProps {
	title: string;
	stat: string;
	showBorder?: boolean;
}
function StatsCard(props: StatsCardProps) {
	const { title, stat, showBorder } = props;
	return (
		<Stat
			px={{ base: 4, md: 8 }}
			py={"5"}
			borderLeft={showBorder ? ["", "1px solid gray"] : ""}
			borderBottomWidth={["1px", "0px"]}
			borderBottomColor="gray"
			borderBottomStyle={"solid"}
		>
			<StatNumber fontSize={"6xl"} fontWeight={"bold"} textAlign="center">
				{stat}
			</StatNumber>
			<StatLabel fontWeight={"semibold"} fontSize={"xl"} textAlign="center">
				{title}
			</StatLabel>
		</Stat>
	);
}

export default function Stats() {
	return (
		<Box
			// maxW="7xl"
			// mx={"auto"}
			py={12}
			pt="20"
			px={{ base: 2, sm: 12, md: 17 }}
			bg={"#E1E6EC"}
		>
			<Text fontWeight={"semibold"} fontSize={"4xl"} textAlign="center" mb="8">
				Why Transact With Us ?
			</Text>
			<SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
				<StatsCard title={"We serve"} stat={"12, 250 "} />
				<StatsCard title={"Total Transactions"} stat={"342,071"} showBorder />
				<StatsCard title={"Customer Savings"} stat={"N50,193,201"} showBorder />
			</SimpleGrid>
		</Box>
	);
}
