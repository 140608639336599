import { object, string, ref } from "yup";

export const SignInValidator = object().shape({
	phone: string()
		.trim()
		.trim()
		.min(7)
		.required("Please enter a valid phone number"),
	password: string().trim().required("Fill in a valid password"),
});

export const SignupValidator = object().shape({
	phone: string().min(7).trim().required("Please enter a valid phone number"),
	email: string()
		.trim()
		.email("Please enter a valid email")
		.required("Please fill you the email field"),
	name: string().required("Fill in your  name"),

	bvn: string().required("Fill in your  BVN"),

	nin: string().required("Fill in your  NIN"),

	password: string().trim().required("Fill in your password"),
});
export const ForgotPasswordValidator = object().shape({
	email: string()
		.trim()
		.email("Please enter a valid email")
		.required("Please fill you the email field"),
});
export const ResetPasswordValidator = object().shape({
	password: string().required("Fill in your password"),
	confirmPassword: string()
		.oneOf([ref("password"), null], "Passwords must match")
		.required("Please confirm your password"),
});
