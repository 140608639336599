import axios, { AxiosAdapter, AxiosInstance } from "axios";
import {
	cacheAdapterEnhancer,
	throttleAdapterEnhancer,
	ICacheLike,
} from "axios-extensions";

declare module "axios" {
	interface AxiosRequestConfig {
		useCache?: boolean | ICacheLike<any>;
	}
}
export class BaseRequest {
	request: AxiosInstance;
	url: string = "http://127.0.0.1:4000";
	apiEndpoint: string | undefined = process.env.REACT_APP_BASE_URL;
	thirdPartyRequest: AxiosInstance;

	constructor() {
		this.request = axios.create({
			baseURL: this.apiEndpoint,
			headers: {
				"Cache-Control": "no-cache",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
			},
			adapter: throttleAdapterEnhancer(
				cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
					cacheFlag: "useCache",
				})
			),
		});
		this.thirdPartyRequest = axios.create({
			headers: {
				"Cache-Control": "no-cache",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
			},
			adapter: throttleAdapterEnhancer(
				cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
					cacheFlag: "useCache",
				})
			),
		});
		this.request.interceptors.response.use(
			function (response) {
				return response;
			},
			async function (error) {
				if (
					window.location.pathname !== "/signup" &&
					window.location.pathname !== "/login"
				) {
					if (
						error.response.status === 401 &&
						localStorage.getItem("auth") !== null
					) {
					}
				}
				return Promise.reject(error);
			}
		);
		this.request.defaults.headers.common["Content-Type"] = "application/json";
	}
}

export const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
