export const FaqData = [
	{
		step: 1,
		details: `Kindly sign up on www.cip.com.ng`,
	},
	{
		step: 2,
		details: `On successful sign up, click on the bundle box on the platform to see the reseller bundle plans..`,
	},
	{
		step: 3,
		details: ` You can fund your wallet by paying into the Wema or Rloez account number on the platform (Your wallet will be automatically funded), kindly click on the bundle box on the platform to convert wallet balance to bundle...`,
	},
	{
		step: 4,
		details: `If you are not buying the reseller bundle, You can still fund and click on the wallet to buy data directly from your wallet balance..`,
	},
	{
		step: 5,
		details: `If you experience any funding issues kindly whats app or call our support line to further resolve or look into the issue..`,
	},
	{
		step: 6,
		details: `09137574484 whatsapp or call..`,
	},
];

export const FaqResellerData = [
	{
		step: 1,
		details: `Kindly sign up on www.cip.com.ng`,
	},
	{
		step: 2,
		details: `A set of Unique Account Numbers are created for you on the platform.`,
	},
	{
		step: 3,
		details: ` Login to your account `,
	},
	{
		step: 4,
		details: `Click on Fund Wallet `,
	},
	{
		step: 5,
		details: `Simply Fund your wallet by paying into any of your unique account numbers on the platform (Your wallet will be automatically funded).`,
	},
	{
		step: 6,
		details: `Sell Data to your Customers, it delivers to them immediately`,
	},
];
