import { Box, Text, Button, Flex } from "@chakra-ui/react";

export const NeedHelp = () => {
	return (
		<Box padding="8% 4%" bg="#F5F2EC" mt="10" mb="5">
			<Flex
				justifyContent={["center", "space-around"]}
				alignItems="center"
				flexDirection={["column", "row"]}
			>
				<Box>
					<Text fontWeight="bold" fontSize={"34px"}>
						Need Help?
					</Text>
					<Text fontSize="18px" fontWeight={500} width={["100%", "70%"]} mt="5">
						Our Team is available around the clock 24/7 to handle your orders,
						inquiries , and your complaints.
					</Text>
				</Box>
				<Button
					size="lg"
					colorScheme="blue"
					bg="#0A335C"
					onClick={() =>
						window.open(
							`https://api.whatsapp.com/send/?phone=09137574484&text=Hi`
						)
					}
				>
					Let's talk
				</Button>
			</Flex>
		</Box>
	);
};
