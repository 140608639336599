import { Box, Image } from "@chakra-ui/react";

export default function CaptionCarousel() {
	const cards = [
		{
			title: "",
			text: "",
			component: (
				<>
					<Box
						display="flex"
						justifyContent={"space-evenly"}
						alignItems="center"
					>
						<Image src="/promo.jpeg" height="100px" />
						<Image src="/promo2.jpeg" height="100px" />
					</Box>
					<Box
						display="flex"
						mt="20"
						justifyContent={"space-evenly"}
						alignItems="center"
					>
						<Image src="/promo3.jpeg" height="100px" />
					</Box>
				</>
			),
		},

		{
			title: "",
			text: "",
			component: (
				<>
					<Box
						display="flex"
						justifyContent={"space-evenly"}
						my="20"
						flexWrap="wrap"
						alignItems="center"
					>
						<Image src="/mtn-main.png" height="100px" />
						<Image src="/glo-main.png" height="100px" />
						<Image src="/9mobile-main.png" height="100px" />
						<Image src="/airtel-main.png" height="100px" />
					</Box>
				</>
			),
		},
	];

	return (
		<Box position={"relative"} width={"full"} overflow={"hidden"}>
			{cards.map((card, index) => (
				<Box key={index}>{card.component}</Box>
			))}
		</Box>
	);
}
