import { Box } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

export const PageLoader = () => {
	return (
		<Box
			width="100%"
			height="100vh"
			display="flex"
			alignItems="center"
			justifyContent={"center"}
			flexDir="column"
		>
			<BeatLoader size={15} color="#36d7b7" loading={true} />
		</Box>
	);
};
