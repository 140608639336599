import { renderClient } from "../main";

const initClient = () => {
	try {
		renderClient();
	} catch (e: any) {
		throw new Error(e);
	}
};

initClient();
