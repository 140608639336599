import { Box, Flex, Image, Text, Button, Spacer } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useResetPasswordHook } from "../../components/auth/auth-hook";
import { TextField } from "../../components/ui/textfield";
import { NavBar } from "./signup";

export const ResetPassword = () => {
	const {
		handleInput,
		errors,
		handleSubmit,
		onSubmit,
		isDisabled,
		isSubmitting,
		control,
	} = useResetPasswordHook();
	console.log(errors);
	return (
		<Box width="100%">
			<NavBar />
			<Flex>
				<Box
					width="60%"
					bg={`linear-gradient(to bottom, #0A7C15BD, #0A7C15BD),url('/slide.jpg')`}
					height="100vh"
					display={["none", "block"]}
				>
					<Flex
						flexDirection={"column"}
						height="100%"
						padding="40px"
						justifyContent="center"
						alignItems={"flex-start"}
					>
						<Image src="/cip-logo.png" width="200px" height="130px" />
						<Text
							fontSize="44px"
							lineHeight={"66px"}
							fontWeight="500"
							color="white"
						>
							At your service, always!
						</Text>
					</Flex>
				</Box>
				<Box width={["100%", "30%"]} margin="10% 80px">
					<Text fontSize="30px" lineHeight={"66px"} fontWeight="700">
						Reset new password
					</Text>
					<form method="post" onSubmit={handleSubmit(onSubmit)}>
						<Text fontSize="16px" color="rgba(0, 0, 0, 0.5)">
							Enter Password
						</Text>
						<Spacer height="20px" />
						<Box>
							<Controller
								control={control}
								name="password"
								render={() => (
									<TextField
										type="password"
										name="phone"
										label="New password"
										error={!!errors.password ? errors.password.message : ""}
										placeholder="New Password*"
										onChange={(e) => handleInput("password", e.target.value)}
									/>
								)}
							/>
						</Box>

						<Spacer height="20px" />

						<Box>
							<Controller
								control={control}
								name="confirmPassword"
								render={() => (
									<TextField
										type="password"
										name="phone"
										label="Confirm new password"
										error={
											!!errors.confirmPassword
												? errors.confirmPassword.message
												: ""
										}
										placeholder="Confirm new Password*"
										onChange={(e) =>
											handleInput("confirmPassword", e.target.value)
										}
									/>
								)}
							/>
						</Box>

						<Spacer height="20px" />

						<Box
							width="100%"
							display="flex"
							flexDir="column"
							alignItems="center"
							marginTop="10px"
						>
							<Button
								color="white"
								fontSize="20px"
								width="238px"
								height="62px"
								type="submit"
								disabled={isDisabled}
								data-form-action={true}
								isLoading={isSubmitting}
								aria-busy={isSubmitting}
								bg="#0A7C15"
								borderRadius="10px"
							>
								Reset
							</Button>
							<Spacer height="50px" />
						</Box>
					</form>
				</Box>
			</Flex>
		</Box>
	);
};
