import { Box, Flex, Image, Text, Button, Spacer } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useForgetPasswordHook } from "../../components/auth/auth-hook";
import { TextField } from "../../components/ui/textfield";
import { NavBar } from "./signup";

export default function ForgottenPassword() {
	const {
		handleInput,
		errors,
		handleSubmit,
		onSubmit,
		isDisabled,
		isSubmitting,
		control,
	} = useForgetPasswordHook();

	return (
		<div>
			{" "}
			<Box width="100%">
				<NavBar />
				<Flex>
					<Box
						width="60%"
						bg={`linear-gradient(to bottom, #0A7C15BD, #0A7C15BD),url('/slide.jpg')`}
						height="100vh"
						display={["none", "block"]}
					>
						<Flex
							flexDirection={"column"}
							height="100%"
							padding="40px"
							justifyContent="center"
							alignItems={"flex-start"}
						>
							<Image src="/cip-white-trans.png" width="200px" height="130px" />
							<Text
								fontSize="44px"
								lineHeight={"66px"}
								fontWeight="500"
								color="white"
							>
								At your service, always!
							</Text>
						</Flex>
					</Box>
					<Box width={["100%", "30%"]} margin="10% 80px">
						<Text fontSize="30px" lineHeight={"66px"} fontWeight="700">
							Forgot Password
						</Text>
						<form method="post" onSubmit={handleSubmit(onSubmit)}>
							<Spacer height="20px" />
							<Box>
								<Controller
									control={control}
									name="email"
									render={() => (
										<TextField
											type="text"
											name="phone"
											label="Email address"
											error={!!errors.email ? errors.email.message : ""}
											placeholder="Email*"
											onChange={(e) => handleInput("email", e.target.value)}
										/>
									)}
								/>
							</Box>
							<Spacer height="20px" />

							<Box
								width="100%"
								display="flex"
								flexDir="column"
								alignItems="center"
								marginTop="10px"
							>
								<Button
									color="white"
									fontSize="20px"
									width="238px"
									height="62px"
									type="submit"
									disabled={isDisabled}
									data-form-action={true}
									isLoading={isSubmitting}
									aria-busy={isSubmitting}
									bg="#0A7C15"
									borderRadius="10px"
								>
									Send
								</Button>
								<Spacer height="50px" />
							</Box>
						</form>
					</Box>
				</Flex>
			</Box>
		</div>
	);
}
