import { Box, Text } from "@chakra-ui/react";
import { FaqData, FaqResellerData } from "./data";

export const Faqs = () => {
	return (
		<>
			<Text fontWeight={"bold"} fontSize="2xl" mb="6" align={"center"}>
				HOW TO JOIN AS A RESELLER ON OUR PLATFORM <br /> AND FUND YOUR WALLET
			</Text>
			<Box
				display="flex"
				justifyContent={"center"}
				flexDirection={["column", "row"]}
				flexWrap="wrap"
			>
				{FaqData.map((data, index) => (
					<Box
						// border="1px solid #ededf2"
						width={["100%", "33%"]}
						p="16px 8px 4px"
						display="flex"
						height="80%"
						alignItems={"center"}
						flexDir="column"
						justifyContent="center"
					>
						<Box
							width="70px"
							height="70px"
							bg="green.500"
							color="white"
							fontWeight={800}
							textAlign={"center"}
							fontSize="28px"
							borderRadius={"100px"}
							display="flex"
							justifyContent={"center"}
							alignItems="center"
						>
							{data.step}
						</Box>
						<Text mt="5" align="center" fontSize="18px" fontWeight="semibold">
							{data.details}
						</Text>
					</Box>
				))}
			</Box>
			<Text
				align="center"
				mt="20"
				fontSize="2xl"
				color="green.800"
				fontWeight="bold"
			>
				We appreciate your Patronage.. <br /> CIP CARES
			</Text>
		</>
	);
};
export const FaqsReseller = () => {
	return (
		<Box py="5">
			<Text fontWeight={"bold"} fontSize="2xl" mb="6" align={"center"}>
				SIMPLE WAY TO JOIN AS A RESELLER/AGENT ON OUR <br /> PLATFORM AND START
				MAKING MONEY IMMEDIATELY
			</Text>
			<Box
				display="flex"
				justifyContent={"center"}
				flexDirection={["column", "row"]}
				flexWrap="wrap"
			>
				{FaqResellerData.map((data, index) => (
					<Box
						// border="1px solid #ededf2"
						width={["100%", "33%"]}
						p="16px 8px 4px"
						display="flex"
						height="80%"
						alignItems={"center"}
						flexDir="column"
						justifyContent="center"
					>
						<Box
							width="70px"
							height="70px"
							bg="green.500"
							color="white"
							fontWeight={800}
							textAlign={"center"}
							fontSize="28px"
							borderRadius={"100px"}
							display="flex"
							justifyContent={"center"}
							alignItems="center"
						>
							{data.step}
						</Box>
						<Text mt="5" align="center" fontSize="18px" fontWeight="semibold">
							{data.details}
						</Text>
					</Box>
				))}
			</Box>
			<Text
				align="center"
				mt="20"
				fontSize="2xl"
				color="green.800"
				fontWeight="bold"
			>
				We appreciate your Patronage.. <br /> CIP CARES
			</Text>
		</Box>
	);
};
