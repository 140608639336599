import LargeWithNewsletter from "./footer/footer";
import { NetworkPage } from "./networks/network-page";
import WithSubnavigation from "./nav/nav";
import Stats from "./stats/stats";
import { Box } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useCallback } from "react";
import { AuthAPIController } from "../../components/auth/auth.controller";
import { authApiRequestImpl } from "../../components/auth/auth.request";
import { NeedHelp } from "./help/need-help";
import { FaqsReseller } from "./faqs/faq";
import { NotificationBar } from "./notification";
import CaptionCarousel from "./slides/slides";
import HeroPage from "./hero/hero";
import WithSpeechBubbles from "./testimonials/testimonials";

export default function Home() {
	const fetchNotificationMessage = useCallback(async () => {
		const request = new authApiRequestImpl();
		const controller = new AuthAPIController(request);
		const response = await controller.getNotification();
		return response;
	}, []);

	const { data } = useQuery("home_notification", () =>
		fetchNotificationMessage()
	);

	return (
		<div>
			<Box position="fixed" zIndex={"9999"} width="100vw">
				{data?.message && <NotificationBar notification={data.message} />}
				<WithSubnavigation />
			</Box>
			<HeroPage />
			<Stats />
			<NetworkPage />
			<NeedHelp />
			<FaqsReseller />
			{/* <Faqs /> */}
			<CaptionCarousel />
			<WithSpeechBubbles />
			<LargeWithNewsletter />
		</div>
	);
}
