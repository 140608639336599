import { Box, Text, Image, Button } from "@chakra-ui/react";
import { Data } from "./network-data";

export const NetworkPage = () => {
	return (
		<Box
			pt="16"
			ml="4"
			display={"flex"}
			justifyContent={"center"}
			alignItems="center"
			flexWrap={"wrap"}
			flexDirection={["column", "row"]}
		>
			{Data.map((data) => (
				<Box
					borderRadius="8px"
					mr={["2", "12"]}
					boxShadow={"2xl"}
					bg={data.color}
					mt="5"
					height={"850px"}
					padding={"4% 0%"}
					border="1px solid #D7D3D3"
					display="flex"
					width={["97%", "25%"]}
					alignItems={"center"}
					flexDirection="column"
				>
					<Text
						textTransform={"uppercase"}
						fontSize="md"
						fontWeight={800}
						textAlign="center"
					>
						{data.title} data
					</Text>
					<Image src={data.img} alt="image" />
					<Text fontWeight={700} fontSize="sm" py="3" mb="4" textAlign="center">
						{data.description}
					</Text>
					<Box
						display="flex"
						height="100%"
						alignItems={"center"}
						width="100%"
						px="10"
						justifyContent="space-between"
						flexDirection="column"
					>
						{data.items.map((item) => (
							<Box
								padding="16px 0px"
								borderBottom="1px solid #f2f2f2	"
								width="100%"
								display="flex"
								alignItems={"center"}
								justifyContent="space-between"
							>
								<Text
									fontSize={"14px"}
									color="white"
									fontWeight="semibold"
									textTransform={"uppercase"}
								>
									{item.plan}
								</Text>
								<Text
									fontSize={"14px"}
									color="white"
									fontWeight="semibold"
									textTransform={"uppercase"}
								>
									{item.price}
								</Text>
							</Box>
						))}
						<Button mt="5">Buy Data</Button>
					</Box>
				</Box>
			))}
		</Box>
	);
};
