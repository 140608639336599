export const Data = [
	{
		color: "#FFC224",
		img: "/mtn.png",
		title: "mtn cg",
		description: "To check balance dial *131*4#",
		items: [
			{
				plan: "500mb",
				price: 180,
			},
			{
				plan: "1gb",
				price: 250,
			},
			{
				plan: "2gb",
				price: 500,
			},
			{
				plan: "3gb",
				price: 750,
			},
			{
				plan: "5gb",
				price: 1250,
			},
			{
				plan: "10gb",
				price: 2500,
			},
			{
				plan: "15gb",
				price: 3750,
			},
		],
	},
	{
		title: "mtn sme",
		color: "#FFC224",
		img: "/mtn.png",
		description: "To check balance dial *461*4#",
		items: [
			{
				plan: "500mb",
				price: 170,
			},
			{
				plan: "1gb",
				price: 230,
			},
			{
				plan: "2gb",
				price: 460,
			},
			{
				plan: "3gb",
				price: 690,
			},
			{
				plan: "5gb",
				price: 1150,
			},
			{
				plan: "10gb",
				price: 2300,
			},
		],
	},
	{
		title: "airtel cg",
		color: "#FF051E",
		img: "/airtel.png",
		description: "To check balance dial *140*4#",

		items: [
			{
				plan: "100mb",
				price: 60,
			},
			{
				plan: "300mb",
				price: 150,
			},
			{
				plan: "500mb",
				price: 250,
			},
			{
				plan: "1gb",
				price: 300,
			},
			{
				plan: "2gb",
				price: 600,
			},
			{
				plan: "5gb",
				price: 1500,
			},
			{
				plan: "10gb",
				price: 3000,
			},
		],
	},
	{
		title: "glo",
		color: "#379F47",
		img: "/glo.png",
		description: "To check balance dial *127*0#",

		items: [
			{
				plan: "200mb",
				price: 100,
			},
			{
				plan: "500mb",
				price: 170,
			},
			{
				plan: "1gb",
				price: 300,
			},
			{
				plan: "2gb",
				price: 600,
			},
			{
				plan: "3gb",
				price: 900,
			},
			{
				plan: "5gb",
				price: 1500,
			},
			{
				plan: "10gb",
				price: 2800,
			},
		],
	},
	{
		title: "9mobile",
		color: "#C4D603",
		img: "/9mobile.png",
		description: "To check balance dial *228#",

		items: [
			{
				plan: "1.5gb",
				price: 930,
			},
			{
				plan: "2gb",
				price: 1120,
			},
			{
				plan: "3gb",
				price: 1400,
			},
			{
				plan: "4.6gb",
				price: 1800,
			},
			{
				plan: "11gb",
				price: 3720,
			},
			{
				plan: "15gb",
				price: 4650,
			},
			{
				plan: "40gb",
				price: 9300,
			},
			{
				plan: "75gb",
				price: 13950,
			},
		],
	},
];
