import { IAuthRequest } from "./auth.request";
import { AppStore } from "../../store/store";

export class AuthAPIController {
	constructor(private readonly request: IAuthRequest) {}

	async login(data: { phone: number; password: string }) {
		const response = await this.request.login(data);
		return response;
	}

	async register(data: {
		name: string;
		email: string;
		phone: number;
		bvn: number;
		nin: number;
		password: string;
	}) {
		const response = await this.request.signup(data);
		return response;
	}
	async forgetPassword(data: { email: string }) {
		const response = await this.request.forgotPassword(data);
		return response;
	}
	async resetPassword(
		data: { email: string; password: string },
		token: string
	) {
		const response = await this.request.resetPassword(data, token);
		return response;
	}

	async validateToken() {
		const response = await this.request.validateToken();
		return response;
	}
	async getUserProfile() {
		try {
			const response = await this.request.userProfile();
			return response;
		} catch {}
	}
	async getNotification() {
		try {
			const response = await this.request.getNotification();
			return response;
		} catch {}
	}
	async getDashboardNotification() {
		try {
			const response = await this.request.getDashboardNotification();
			return response;
		} catch {}
	}
	async refreshToken() {
		const response = await this.request.refreshToken();

		if (response) {
			if (response.access_token) {
				localStorage.setItem("token", response.access_token);
				AppStore.setToken(response.access_token);
			}
		}

		return response;
	}
}
